
import Vue from 'vue';
import Wizard from '@/components/Wizard.vue';
import TimeTicker from '@/components/TimeTicker.vue';
import TimeoutPopup from '@/wizard/Tommy/Components/TimeoutPopup.vue';
import { mapGetters } from 'vuex';
import { wizardProvider, TommyHelper } from '@/wizard/';
import moment from 'moment';
import {TokenOptionsInterface} from "@/wizard/Tommy/Setup/widget-config.interface";
import axios from 'axios';

moment.updateLocale('nl', { week: { dow: 0, doy: 4 } });
moment.updateLocale('en', { week: { dow: 0, doy: 4 } });
moment.updateLocale('de', { week: { dow: 0, doy: 4 } });

moment.locale('nl');

export default Vue.extend({
  name: 'App',
  components: {
    Wizard,
    TimeTicker,
    TimeoutPopup,
  },
  props: {
    usePin: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    accountId: {
      type: String,
      required: false,
      default: () => false,
    },
    theme: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    colorLoader: {
      type: String,
      required: false,
    },
    colorZoomBg: {
      type: String,
      required: false,
    },
    colorZoomFg: {
      type: String,
      required: false,
    },
    mapsKey: {
      type: String,
      required: false,
    },
    entrypoints: {
      type: Array,
      required: false,
      default: () => ['start-booking', 'start-reserved'],
    },
    travelGroupLimits: {
      type: Object,
      required: false,
      default: () => {},
    },
    accommodationGroups: {
      type: Array,
      required: false,
      default: () => [] as any,
    },
    maxDuration: {
      type: Number,
      required: false,
      default: () => null as any,
    },
    salesChannel: {
      type: Number,
      required: false,
      default: () => null as any,
    },
    translations: {
      type: Object,
      required: false,
      default: () => {},
    },
    petsConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          articles: [] as any,
          max: 0 as number,
        };
      },
    },
    extrasConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          disabled: [] as any,
        };
      },
    },
    alternativeResults: {
      type: Boolean,
      required: true,
      default: () => {
        return false as boolean;
      },
    },
    accommodationLabels: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    useMarkerClusterer: {
      type: Boolean,
      required: false,
      default: () => {
        return false as boolean;
      },
    },
    useReductionPass: {
      type: Boolean,
      required: false,
      default: () => {
        return true as boolean;
      },
    },
    useTimeTable: {
      type: Boolean,
      required: false,
      default: () => {
        return false as boolean;
      },
    },
    arrivalPrinter: {
      type: String,
      required: false,
      default: () => {
        return '' as string;
      },
    },
    disableMaps: {
      type: Boolean,
      required: false,
      default: () => {
        return false as boolean;
      },
    },
    disableActioncode: {
      type: Boolean,
      required: false,
      default: () => {
        return false as boolean;
      },
    },
    minimalMaps: {
      type: Boolean,
      required: false,
      default: () => {
        return false as boolean;
      },
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      timeTableStatus: undefined,
      timetableDisabled: false,
      timetableDisableColumn: false,
      timetableDisableSignup: false,
      ready: false,
    };
  },
  async created() {
    let startdate: any = moment();
    if (process.env.VUE_APP_DATE_OFFSET) {
      startdate.add(parseInt(process.env.VUE_APP_DATE_OFFSET), 'days');
    }
    this.$store.commit('SET_START_DATE', startdate.format('YYYY-MM-DD'));
    this.$store.commit('SET_PETS_CONFIG', this.petsConfig);
    this.$store.commit('SET_EXTRAS_CONFIG', this.extrasConfig);

    if (this.salesChannel) {
      this.$store.commit('SET_TOMMY_SALESCHANNEL', this.salesChannel);
    }

    if (document && this.theme) {
      document.getElementsByTagName('html')[0].classList.add(this.theme);
      document.body.classList.add(this.theme);
    }

    const token: any = '' + this.token;
    const split: any = token.split(':');

    this.$store.commit('SET_META', {
      theme: this.theme,
      usePin: this.usePin,
      accountId: this.accountId || undefined,
      token: token,
      user: this.theme,
      hash: split[1],
      accommodationGroups: this.accommodationGroups || [],
      mapsKey: this.mapsKey || undefined,
      colorLoader: this.colorLoader || undefined,
      colorZoomBg: this.colorZoomBg || undefined,
      colorZoomFg: this.colorZoomFg || undefined,
      entrypoints: this.entrypoints,
      travelGroupLimits: Object.assign({}, this.travelGroupLimits),
      maxDuration: this.maxDuration,
      salesChannel: this.salesChannel,
      useReductionPass: this.useReductionPass,
      accommodationLabels: this.accommodationLabels,
      alternativeResults: this.alternativeResults,
      useMarkerClusterer: this.useMarkerClusterer,
      disableMaps: this.disableMaps,
      disableActioncode: this.disableActioncode,
      minimalMaps: this.minimalMaps,
      useTimeTable: this.useTimeTable,
      arrivalPrinter: this.arrivalPrinter,
    } as TokenOptionsInterface);
    if (this.translations) {
      this.$store.commit('UPDATE_TRANSLATION_CONTEXT', this.translations);
    }
    const helper = new TommyHelper(this.$store);
    wizardProvider.setHelper(helper);
    await helper.setup();
    await this.checkTimeTable();
    this.ready = true;
  },
  async mounted() {
    await this.checkTimeTable();
    wizardProvider.init(this.$store);

    this.$app.provider = wizardProvider;
    this.$app.helper = wizardProvider.helper;

    if (typeof (window as any).timeTableInterval !== 'undefined') {
      clearInterval((window as any).timeTableInterval);
    }

    (window as any).timeTableInterval = setInterval(async () => {
      return this.checkTimeTable(true);
    }, 10000);
  },
  methods: {
    async reload() {
      return document.location.reload();
    },
    async onStepChange(data: any) {
      if (data.stepNumber === 1) {
        await this.checkTimeTable();
      }
    },
    async checkTimeTable(refresh: boolean = false) {
      const meta = this.$store.getters.meta;
      if (!meta.useTimeTable) {
        return;
      }

      const axiosClient = axios.create({
        baseURL: process.env.VUE_APP_TIMETABLE_BASE_URL || 'https://www.timetable-gaas.3wstaging.nl',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': meta.token,
        },
      });

      const currentStatus = this.timeTableStatus !== undefined ? JSON.parse(JSON.stringify(this.timeTableStatus)) : undefined;
      const response = await axiosClient.get('api/status');
      this.timeTableStatus = parseInt(response.data.status);
      this.timetableDisabled = 4 === this.timeTableStatus;
      this.timetableDisableColumn = 3 === this.timeTableStatus;
      this.timetableDisableSignup = 2 === this.timeTableStatus;

      this.$store.dispatch('setTimetableEnabled', true);

      if (refresh && typeof currentStatus !== 'undefined' && currentStatus !== this.timeTableStatus) {
        this.$store.dispatch('setTimetableStatus', this.timeTableStatus);
        clearInterval((window as any).timeTableInterval);
        document.location.reload();
      } else {
        this.$store.dispatch('setTimetableStatus', this.timeTableStatus);
      }
    },
    prev() {
      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('updateMountKey');
      this.$store.dispatch('previousStep');
    },
    quit() {
      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('updateMountKey');
      this.$store.commit('SET_STEP_NUMBER', 1);
      this.$store.dispatch('quit');
    },
  },
  computed: {
    ...mapGetters(['config', 'stepNumber', 'stepCount', 'locale']),
  },
});
