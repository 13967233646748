
import Vue from 'vue';
import { wizardProvider } from '@/wizard';
const countries = require('i18n-iso-countries');

export default Vue.extend({
  props: {
    widget: {
      type: Object,
      default: () => false as any,
    },
  },
  data() {
    const days = [];
    const months = [];
    const years = [];

    for (let i = 1; i <= 31; i++) {
      days.push(`${i}`.padStart(2, '0'));
    }

    for (let i = 1; i <= 12; i++) {
      months.push(`${i}`.padStart(2, '0'));
    }

    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }

    return {
      wizardProvider: wizardProvider,
      formdata: {} as any,
      formerrors: {} as any,
      ready: false,
      countryList: [],
      zipcodeDetails: {
        huisnummer: false,
        postcode: false
      },
      days,
      months,
      years
    };
  },
  computed: {
    name(): any {
      const options: any = this.widget.options;

      return options.type || '';
    },
    fields(): any {
      const options: any = this.widget.options;

      return options.fields || [];
    },
    zipcode(): any {
      const options: any = this.widget.options;

      return options.zipcode || [];
    },
  },
  mounted() {
    this.countryList = Object.entries(countries.getNames(this.$store.getters.locale || 'en')).map((item: any) => {
      return {
        value: item[0],
        label: item[1],
      };
    });

    const fields: any = this.fields || [];
    fields.forEach((field: any) => {
      let typeDefault: any = '';
      if ('checkbox' === field.type) {
        typeDefault = 0;
      }

      if ('country' === field.type) {
        switch (this.$store.getters.locale) {
          case 'de':
            typeDefault = 'DE';
            break;
          case 'nl':
          default:
            typeDefault = 'NL';
            break;
        }
      }

      if ('select' === field.type && field.required) {
        typeDefault = '' + (field.choices.length ? field.choices[0].value : '');
      }

      if ('birthdate' === field.type) {
        typeDefault = `${this.years[this.years.length - 18]}-${this.months[0]}-${this.days[0]}`;
      }

      this.formdata[field.name] = field.default || typeDefault;

      if ('birthdate' === field.type) {
        this.formdata[field.name + '-day'] = this.days[0];
        this.formdata[field.name + '-month'] = this.months[0];
        this.formdata[field.name + '-year'] = this.years[this.years.length - 18];
      }
    });

    this.ready = true;
  },
  methods: {
    isFieldRequired(field: any): boolean {
      return true === (field.required || false);
    },
    getFieldPlaceholder(field: any): string {
      const fieldName: string = field.name;
      if (true !== (this.formerrors[fieldName] || false)) {
        if ('' !== '' + field.placeholder) {
          return field.placeholder;
        }

        return '';
      }

      const label: any = this.getFieldLabel(field);

      return this.wizardProvider.translate('required-field-error', { field: label.toLowerCase() });
    },
    getFieldLabel(field: any): string {
      const options: any = field.options || {};
      const translate: any = typeof options.translate === 'undefined' ? true : options.translate;

      if (translate) {
        return this.wizardProvider.translate(field.label);
      }

      return field.label;
    },
    getRowClasses(field: any): string[] {
      const fieldName: string = field.name;
      const classes: string[] = ['form-field-' + fieldName, 'form-field-type-' + field.type];
      if (field.required) {
        classes.push('form-required');
      }

      if (this.formerrors[fieldName] || false) {
        classes.push('form-error');
      }

      return classes;
    },
    getFieldClassses(field: any, defaultClasses: any = []): string[] {
      const classes: any = [];
      defaultClasses.forEach((cls: any) => {
        classes.push(cls);
      });

      if (field.type === 'checkbox') {
        return classes;
      }

      classes.push('form-control');

      return classes;
    },
    async validate(validateField?: any): Promise<boolean> {
      let valid: boolean = true;

      if (!validateField) {
        this.formerrors = {};
      }

      this.fields.forEach((field: any) => {
        // when validating single field, skip other fields.
        if (validateField && validateField.name !== field.name) {
          return;
        }

        const fieldName: string = field.name;
        const type: string = field.type;

        if ('huisnummer' === fieldName) {
          this.zipcodeDetails.huisnummer = this.formdata[fieldName];
        }

        if ('postcode' === fieldName) {
          this.zipcodeDetails.postcode = this.formdata[fieldName];
        }

        if (validateField) {
          Vue.delete(this.formerrors, field.name);
        }

        if ('email' === type) {
          var emailPattern = /^.+@.+\..+$/;
          if (!emailPattern.test(this.formdata[fieldName])) {
            valid = false;
            this.formerrors[field.name] = true;
          }
        } else if ('birthdate' === type) {
          const d = this.formdata[field.name + '-day'];
          const m = this.formdata[field.name + '-month'];
          const y = this.formdata[field.name + '-year'];

          if (!d || !m || !y) {
            valid = true;
            this.formerrors[field.name] = false;
            return;
          }

          this.formdata[fieldName] = `${d}-${m}-${y}`;
          var birthdatePattern = /^\d{1,2}-\d{1,2}-\d{4}$/;
          if (!birthdatePattern.test(this.formdata[fieldName])) {
            valid = false;
            this.formerrors[field.name] = true;
          } else {
            const date = new Date(y, m - 1, d);
            if (date.getFullYear() != y || date.getMonth() != m - 1 || date.getDate() != d) {
              valid = false;
              this.formerrors[field.name] = true;
            }
          }
        }

        if ((field.required && '' == this.formdata[fieldName]) || '') {
          valid = false;
          this.formerrors[field.name] = true;
        }
      });

      const client = wizardProvider.helper.getClient();
      if (this.zipcode && client && !this.formdata['plaats'] && this.formdata['postcode'] && this.formdata['huisnummer']) {
        return client.checkZipCode(this.zipcodeDetails).then((response: any) => {
          if (response && response.success && !!response.data) {
            Vue.set(this.formdata, 'straat', response.data.street || '');
            Vue.set(this.formdata, 'plaats', response.data.city.label || '');
            Vue.set(this.formdata, 'land', 'NL');

            // re-render to show new input data.
            this.ready = false;
            setTimeout(() => {
              this.ready = true;
            }, 5);
          }

          return valid;
        }).catch((error: any) => {
          valid = false;

          return valid;
        });
      }

      return valid;
    },
    async triggerClick() {
      if (await this.validate()) {
        this.fields.forEach((field: any) => {
          if ('birthdate' === field.type) {
            this.formdata[field.name] = `${this.formdata[field.name + '-year']}-${this.formdata[field.name + '-month']}-${this.formdata[field.name + '-day']}`;
            delete this.formdata[field.name + '-year'];
            delete this.formdata[field.name + '-month'];
            delete this.formdata[field.name + '-day'];
          }
        });
        this.$emit('choose', { type: this.name, data: this.formdata });
      }
    },
    getType(field: any) {
      return field.type || 'text';
    },
  },
});
