export default class ReceiptModel {
  constructor(public params: any, public response: any) {
    const keys: any = Object.keys(response);
    if (!keys || keys.length <= 0) {
      throw 'Could not find result.';
    }

    this.response = response[keys[0]];
  }

  accommodationId() {
    return this.response.id;
  }

  accommodationName() {
    return this.response.naam;
  }

  duration() {
    return this.params.duration;
  }

  articleRows() {
    const prijzen: any = this.response.prijzen || {};
    const artikelen: any = prijzen.artikelen || {};
    const artikelenBerekening: any = artikelen.berekening || {};

    const rows: any = [];
    Object.keys(artikelenBerekening).forEach((key: any) => {
      const item: any = artikelenBerekening[key];
      item.id = key;

      rows.push(item);
    });

    return rows;
  }

  reductionRows() {
    const prijzen: any = this.response.prijzen || {};
    const kortingen: any = prijzen.kortingen || {};
    const kortingenBerekening: any = kortingen.berekening || {};

    const rows: any = [];
    Object.keys(kortingenBerekening).forEach((key: any) => {
      const item: any = kortingenBerekening[key];
      item.id = key;

      rows.push(item);
    });

    return rows;
  }
}
