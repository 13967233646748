
import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  data() {
    return {
      interval: false as any,
      date: new Date(),
    };
  },
  async mounted() {
    const inst: any | Vue = this;
    clearInterval(this.interval);
    this.interval = setInterval(function() {
      inst.date = new Date();
    }, 1000);
  },
});
