import { HelperInterface } from '@/model/helper.interface';
import { StepInterface, ConfigInterface } from '@/model';
import { Store } from 'vuex';

export default class NoopHelper implements HelperInterface {
  constructor(public store: Store<any>) {}

  private translations: any;

  setup(): void {}

  getClient(): any {
    return null;
  }

  provideTranslations(): any {
    return {};
  }

  translate(key: any, context?: any, options?: any) {
    if (!context) {
      context = {};
    }

    let locale: any = (options || {}).locale || false;
    if (!locale) {
      locale = this.store.getters.locale;
    }

    if (!locale) {
      return '';
    }

    if (typeof key === 'undefined' || '' === '' + key) {
      return '';
    }

    if (!this.translations) {
      this.translations = this.provideTranslations();
    }

    const translationLocales: any = this.translations[key] || key;

    let translation: string;

    // preserve empty translation.
    if (typeof translationLocales[locale] !== 'undefined' && '' === translationLocales[locale]) {
      translation = '';
    } else {
      // non-empty translation visual dev/prod mode.
      if (process.env.NODE_ENV !== 'production') {
        translation = translationLocales[locale] || '[__' + locale + ':' + key + ']';
      } else {
        translation = translationLocales[locale] || key;
      }
    }

    const translationContext: any = this.store.getters.translationContext;
    Object.keys(context).forEach((key: any) => {
      translationContext[key] = context[key];
    });

    Object.keys(translationContext).forEach((key: string) => {
      const value: any = translationContext[key];
      const regex: string = '%' + key + '%';

      translation = translation.replace(new RegExp(regex, 'g'), value);
    });

    return translation;
  }

  getConfig(): ConfigInterface {
    return {} as ConfigInterface;
  }

  customizeStep(step: StepInterface, selection: any, choices: any): StepInterface {
    return step;
  }

  async stepDone(step: StepInterface, selection: any, choices: any): Promise<any> {
    return true;
  }

  async overrideStep(step: StepInterface, selection: any, choices: any): Promise<boolean | StepInterface> {
    return false;
  }

  buildUrl(selection: any, choices: any): string {
    return '/' + JSON.stringify(choices);
  }
}
