export default class AvailabilityAccommodationModel {
  constructor(public receiptAccommodation: any) {}

  accommodationId(): any {
    return this.receiptAccommodation.accommodation || false;
  }

  receiptPriceObject(): any {
    const price: any = this.receiptAccommodation.price || {};

    return price || {};
  }

  priceStay(): number {
    return this.receiptPriceObject().costsOfStay.total.excl || 0;
  }

  items(): number {
    return this.receiptPriceObject().items.calculation || [];
  }

  priceExclVat(): number {
    return this.receiptPriceObject().total.excl || 0;
  }

  priceVat(): number {
    return this.receiptPriceObject().total.vat || 0;
  }

  priceTotal(): number {
    return this.receiptPriceObject().total.incl || 0;
  }
}
