
import Vue from 'vue';
import { wizardProvider } from '@/wizard';

export default Vue.extend({
  props: {
    identifier: {
      type: String,
      required: true,
    },
    result: {
      type: Object,
      required: true,
    },
    alternative: {
      type: Boolean,
      default: () => false,
    },
    labelExtra: {
      type: String,
      required: false,
      default: () => undefined as string,
    },
    description: {
      type: String,
      required: false,
      default: () => undefined as string,
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
    };
  },
});
