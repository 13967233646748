import { ConfigInterface } from '@/model/';

const config: ConfigInterface = {
  user: 'example',
  metadata: {},
  steps: [
    {
      type: 'default',
      code: 'language',
      title: '',
      buttons: [
        {
          label: 'Boek een kampeerplaats',
          code: 'nl',
          slots: { top: '<div class="flag"><span>Nederlands</span><img src="/img/icons/nl.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Buchen Sie einen Campingplatz',
          code: 'de',
          slots: { top: '<div class="flag"><span>Deutsch</span><img src="/img/icons/de.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Book a camping site',
          code: 'en',
          slots: { top: '<div class="flag"><span>English</span><img src="/img/icons/uk.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Réservez un camping',
          code: 'fr',
          slots: { top: '<div class="flag"><span>Français</span><img src="/img/icons/fr.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Boka en campingplats',
          code: 'se',
          slots: { top: '<div class="flag"><span>Svenska</span><img src="/img/icons/se.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Reserve un camping',
          code: 'es',
          slots: { top: '<div class="flag"><span>Espanol</span><img src="/img/icons/es.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Prenota un campeggio',
          code: 'it',
          slots: { top: '<div class="flag"><span>Italiano</span><img src="/img/icons/it.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Bestill en campingplass',
          code: 'no',
          slots: { top: '<div class="flag"><span>Norsk</span><img src="/img/icons/no.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
        {
          label: 'Reserve um parque de campismo',
          code: 'pt',
          slots: { top: '<div class="flag"><span>Português</span><img src="/img/icons/pt.svg" /></div>' },
          options: {
            translate: false,
            classes: ['language'],
          },
        },
      ],
    },
    {
      type: 'default',
      code: 'intro',
      title: 'title-intro',
      buttons: [
        {
          label: 'intro-booking',
          code: 'start-booking',
          options: { classes: ['entrypoint'] },
        },
        {
          label: 'intro-reservation',
          code: 'start-reserved',
          options: {
            classes: ['entrypoint'],
          },
        },
      ],
      layout: {
        rows: [
          {
            columns: [
              {
                type: 'text',
                classes: ['col-12'],
                data: 'intro-text-1',
              },
            ],
          },
          {
            columns: [
              {
                type: 'buttons',
                classes: ['col-12'],
              },
            ],
          },
          {
            columns: [
              {
                type: 'text',
                classes: ['col-12'],
                data: 'intro-text-2',
              },
            ],
          },
        ],
      },
    },
    {
      type: 'default',
      code: 'travelgroup',
      title: 'travelgroup-title',
      buttons: [],
      options: {
        widget: { type: 'travelgroup', options: {} },
      },
    },
    {
      type: 'default',
      code: 'duration',
      title: 'duration-title',
      buttons: [],
      options: {
        widget: { type: 'duration', options: {} },
      },
    },
    {
      type: 'default',
      code: 'accommodation',
      title: 'accommodation-title',
      buttons: [],
      options: {
        widget: { type: 'accommodation', options: { title: 'accommodation-title', intro: 'accommodation-text-1' } },
      },
      layout: {
        rows: [
          {
            columns: [
              {
                type: 'buttons',
                classes: ['col-12'],
              },
              {
                type: 'widget',
                classes: ['col-12'],
              },
            ],
          },
        ],
      },
    },
    {
      type: 'default',
      code: 'pets',
      title: 'pets-title',
      buttons: [],
      options: {
        widget: { type: 'pets', options: {} },
      },
    },
    {
      type: 'default',
      code: 'reductionpass',
      title: 'reductionpass-title',
      buttons: [],
      options: {
        widget: { type: 'reductionpass', options: {} },
      },
    },
    {
      type: 'default',
      code: 'floorplan',
      title: 'floorplan-title',
      buttons: [],
      options: {
        widget: { type: 'floorplan', options: {} },
      },
    },
    {
      type: 'default',
      code: 'extras',
      title: 'extras-title',
      buttons: [],
      options: {
        widget: { type: 'extras', options: {} },
      },
    },
    {
      type: 'default',
      code: 'receipt',
      title: '',
      buttons: [],
      options: {
        widget: { type: 'receipt', options: {} },
      },
    },
    {
      type: 'default',
      code: 'personaldata',
      title: 'personaldata-title',
      buttons: [],
      options: {
        widget: {
          type: 'form',
          options: {
            zipcode: {
              enabled: true
            },
            fields: [
              {
                type: 'select',
                label: 'salution',
                name: 'aanhef',
                choices: [
                  { value: 'mv', label: 'salution-malefemale' },
                  { value: 'm', label: 'salution-male' },
                  { value: 'v', label: 'salution-female' },
                  { value: 'fam', label: 'salution-family' },
                ],
                required: true,
                forceShow: true,
              },
              {
                type: 'text',
                label: 'firstname',
                name: 'voornaam',
                required: false,
                forceShow: true,
              },
              {
                type: 'text',
                label: 'infix',
                name: 'tussenvoegsel',
                classes: ['sm'],
                required: false,
                forceShow: true,
              },
              {
                type: 'text',
                label: 'surname',
                name: 'achternaam',
                required: true,
                forceShow: true,
              },
              {
                type: 'email',
                label: 'email',
                name: 'email',
                required: true,
                forceShow: true,
              },
              {
                type: 'text',
                label: 'telephone',
                name: 'telefoon',
                required: true,
                forceShow: true,
              },
              {
                type: 'country',
                label: 'country',
                name: 'land',
                required: false,
                alias: 'Adresgegevens',
              },
              {
                type: 'text',
                label: 'zipcode',
                name: 'postcode',
                required: false,
                classes: ['sm'],
                alias: 'Adresgegevens',
              },
              {
                type: 'text',
                label: 'housenumber',
                name: 'huisnummer',
                required: false,
                classes: ['sm'],
                alias: 'Adresgegevens',
              },
              {
                type: 'text',
                label: 'street',
                name: 'straat',
                required: false,
                alias: 'Adresgegevens',
              },
              {
                type: 'text',
                label: 'place',
                name: 'plaats',
                required: false,
                alias: 'Adresgegevens',
              },
              {
                type: 'birthdate',
                label: 'birthdate',
                name: 'geboortedatum',
                placeholder: 'dd-mm-jjjj',
                required: false,
                classes: ['sm'],
                alias: 'Geboortedatum',
              },
              {
                type: 'text',
                label: 'license-plate',
                name: 'kenteken',
                required: false,
                classes: ['sm'],
                forceShow: false,
              },
            ],
          },
        },
      },
    },
    {
      type: 'default',
      code: 'confirm',
      title: 'confirm-title',
      buttons: [],
      options: {
        widget: { type: 'receipt-details', options: {} },
      },
    },
    {
      type: 'default',
      code: 'finish',
      title: 'finish-title',
      buttons: [],
      options: {
        widget: { type: 'pin', options: {} },
      },
      layout: {
        rows: [
          {
            columns: [
              {
                type: 'text',
                classes: ['col-12'],
                data: 'finish-text-1',
              },
            ],
          },
          {
            columns: [
              {
                type: 'text',
                classes: ['col-12'],
                data: 'finish-text-2',
              },
            ],
          },
          {
            columns: [
              {
                type: 'buttons',
                classes: ['col-12'],
              },
              {
                type: 'widget',
                classes: ['col-12'],
              },
            ],
          },
        ],
      },
    },
  ],
};

export default config;
