
import Vue from 'vue';
import { wizardProvider, TommyHelper } from '@/wizard';
import moment from 'moment';
import { TommyClient } from '../TommyClient';
import ReceiptModel from './model/ReceiptModel';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      wizardProvider: wizardProvider,
      helper: wizardProvider.helper as TommyHelper,
      ready: false,
      prijstriggers: [],
    };
  },
  computed: {
    ...mapGetters(['stepNumber']),
  },
  async mounted() {
    const meta: any = this.$store.getters.meta;
    const choices: any = this.$store.getters.choices;
    if (false === meta.useReductionPass) {
      this.$store.commit('ADD_SKIPPED_STEP', this.stepNumber);
      this.$emit('choose', { data: false });
      return;
    }

    await this.helper.client.getCalender().then((response: any) => {
      const prijstriggersData: any = response.data.data.prijstriggers;
      const keys: any = Object.keys(prijstriggersData);

      if (0 === keys.length) {
        this.$store.commit('ADD_SKIPPED_STEP', this.stepNumber);
        this.$emit('choose', { data: false });
      }

      this.prijstriggers = Object.values(prijstriggersData);

      this.ready = true;
    });
  },
  methods: {
    triggerClick: function(value: any) {
      this.$emit('choose', { data: value });
    },
  },
});
