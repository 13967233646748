
import Vue from 'vue';
import {wizardProvider} from "@/wizard";

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      wizardProvider: wizardProvider,
    };
  },
  mounted() {
    this.localValue = this.value || 0;
  },
  methods: {
    getClasses(type: string) {
      return ['btn', 'btn-primary', 'btn-circle', 'btn-xl', 'handle-' + type];
    },
    yes() {
      this.localValue = 1;
      this.$emit('input', 1);
    },
    no() {
      this.localValue = 0;
      this.$emit('input', 0);
    },
  },
});
