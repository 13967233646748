import AvailabilityAccommodationModel from './AvailabilityAccommodationModel';

export default class AvailabilityModel {
  public accommodations: AvailabilityAccommodationModel[] = [];

  constructor(public params: any, public response: any) {
    response.accommodations.map((item: any) => {
      this.accommodations.push(new AvailabilityAccommodationModel(item));
    });
  }

  getAccommodation(accommodationId: any): AvailabilityAccommodationModel | null {
    let res: any = null;
    (this.accommodations || []).forEach((receiptAccommodation: AvailabilityAccommodationModel) => {
      if ('' + receiptAccommodation.accommodationId() === '' + accommodationId) {
        res = receiptAccommodation;
      }
    });

    return res;
  }

  duration() {
    return this.params.duration;
  }

  dateStart(): any {
    return this.response.period.start || null;
  }

  dateEnd(): any {
    return this.response.period.end || null;
  }
}
