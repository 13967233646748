import { ConfigInterface } from '@/model/';
import gqlFixture from './gql.json';

const config: ConfigInterface = {
  user: 'droomparken',
  metadata: {
    gql: gqlFixture.data,
  },
  steps: [
    {
      type: 'default',
      code: 'reisgezelschap',
      title: 'Reisgezelschap',
      buttons: [
        { label: "m'n lief", code: 'lief' },
        { label: "de kiddo's'", code: 'kiddos' },
        { label: 'het clubje', code: 'clubje' },
        { label: 'héééééél veel', code: 'heelveel' },
      ],
    },
    {
      type: 'personen',
      code: 'aantal',
      title: 'Reisgezelschap',
      buttons: [],
    },
    {
      type: 'default',
      code: 'duur',
      title: 'Duur',
      buttons: [
        {
          label: 'weekend',
          code: 'weekend',
        },
        {
          label: 'midweek',
          code: 'midweek',
        },
        { label: 'week', code: 'week' },
      ],
    },
    {
      type: 'verblijf',
      code: 'wanneer',
      title: 'Wanneer',
      buttons: [],
    },
    {
      type: 'default',
      code: 'welkeregio',
      title: 'Welke regio',
      buttons: [
        { label: 'Limburg', code: 'limburg' },
        { label: 'Zeeuwse kust', code: 'zeeuwsekust' },
        { label: 'De Veluwe', code: 'develuwe' },
        { label: 'Noord Holland', code: 'noordholland' },
        { label: 'Achterhoek', code: 'achterhoek' },
      ],
    },
    {
      type: 'default',
      code: 'waardroomjegraag',
      title: 'Waar droom je graag',
      buttons: [
        { label: 'studio', code: 'studio', options: { max: 2 } },
        {
          label: 'chalet',
          code: 'chalet',
          options: {},
        },
        {
          label: 'cube',
          code: 'cube',
          options: {},
        },
        {
          label: "villa's & groepen",
          code: 'villas',
          options: {},
        },
        {
          label: 'tiny house',
          code: 'tinyhouse',
          options: { min: 2, max: 4 },
        },
        {
          label: 'kamperen',
          code: 'kamperen',
          options: {},
        },
      ],
    },
  ],
};

export default config;
