
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { wizardProvider } from '@/wizard/';

export default Vue.extend({
  data() {
    return {
      wizardProvider: wizardProvider,
    };
  },
  methods: {
    dismiss(): void {
      this.$store.commit('SET_NOTIFY_VISIBLE', false);
    },
  },
  computed: {
    ...mapGetters({
      message: 'notifyMessage',
      visible: 'notifyVisible',
    }),
  },
});
