
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { wizardProvider } from '@/wizard/';
let quitTimeout: any = false;

export default Vue.extend({
  data() {
    return {
      wizardProvider: wizardProvider,
    };
  },
  watch: {
    visible: function(newValue: boolean): boolean {
      clearTimeout(quitTimeout);
      if (true === newValue) {
        quitTimeout = setTimeout(() => this.quit(), parseInt(process.env.VUE_APP_RESUME_TIMEOUT));
      }

      return newValue;
    },
  },
  methods: {
    dismiss(): void {
      clearTimeout(quitTimeout);
      this.$store.commit('SET_TIMEOUT_VISIBLE', false);
    },
    quit(): void {
      clearTimeout(quitTimeout);

      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('quit');
      this.$store.dispatch('updateMountKey');
      this.$store.commit('SET_STEP_NUMBER', 1);

      this.dismiss();
    },
  },
  computed: {
    ...mapGetters({
      visible: 'timeoutVisible',
    }),
  },
});
