import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'maarnseberg';

const currentButtons = [...newConfig.steps[0].buttons];
const newButtons = [];

for (const currentButton of currentButtons) {
    const btn = JSON.parse(JSON.stringify(currentButton));
    btn.code = `${btn.code}-default`;
    btn.options.salesChannel = 18035;
    btn.options.maxDuration = 99;
    if (!btn.options.classes) {
        btn.options.classes = [];
    }

    newButtons.push({ ...btn });
}

for (const currentButton of currentButtons) {
    const btn = JSON.parse(JSON.stringify(currentButton));
    switch (btn.code) {
        case 'nl':
            btn.label = 'Aanmelden bezoek';
            break;
        case 'en':
        case 'gb':
            btn.label = 'Register visit';
            break;
    }


    btn.code = `${btn.code}-bezoek`;
    btn.options.salesChannel = 18038;
    btn.options.maxDuration = 1;
    btn.options.perDay = true;
    btn.options.disableFloorplan = true;
    if (!btn.options.classes) {
        btn.options.classes = [];
    }

    if (currentButton.code === currentButtons[0].code) {
        btn.options.classes.push('btn-break-always');
    }

    newButtons.push({ ...btn });
}

newConfig.steps[0] = {
    "type": "default",
    "code": "language",
    "title": "",
    "buttons": newButtons
}


export default newConfig;
