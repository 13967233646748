import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'camperparkamsterdam';

const currentButtons = [...newConfig.steps[0].buttons];
const newButtons = [];

for (const currentButton of currentButtons) {
    const btn = JSON.parse(JSON.stringify(currentButton));
    btn.code = `${btn.code}-default`;
    btn.options.salesChannel = 20884;
    if (!btn.options.classes) {
        btn.options.classes = [];
    }

    newButtons.push({ ...btn });
}

for (const currentButton of currentButtons) {
    const btn = JSON.parse(JSON.stringify(currentButton));
    switch (btn.code) {
        case 'nl':
        case 'nl-default':
            btn.label = 'Bestaande reservering ophalen';
            break;
        case 'en':
        case 'en-default':
        case 'gb':
        case 'gb-default':
            btn.label = 'Retrieve existing reservation';
            break;
        case 'de':
        case 'de-default':
            btn.label = 'Bestehende Reservierung abrufen';
            break;
        case 'fr':
        case 'fr-default':
            btn.label = 'Récupérer une réservation existante';
            break;
        case 'pt':
        case 'pt-default':
            btn.label = 'Recuperar reserva existente';
            break;
        case 'it':
        case 'it-default':
            btn.label = 'Recupera una prenotazione esistente';
            break;
        case 'es':
        case 'es-default':
            btn.label = 'Recuperar reserva existente';
            break;
    }

    btn.code = `${btn.code}-bezoek`;
    btn.options.signupVisit = true;
    btn.options.salesChannel = 20508;
    if (!btn.options.classes) {
        btn.options.classes = [];
    }

    if (currentButton.code === currentButtons[0].code) {
        btn.options.classes.push('btn-break-always');
    }

    newButtons.push({ ...btn });
}

newConfig.steps[0] = {
    "type": "default",
    "code": "language",
    "title": "",
    "buttons": newButtons
}
newConfig.steps[0].buttons.forEach((button: any) => {
    switch (button.code) {
        case 'nl':
        case 'nl-default':
            button.label = 'Nieuwe reservering plaatsen';
            break;
        case 'de':
        case 'de-default':
            button.label = 'Neue Reservierung vornehmen';
            break;
        case 'en':
        case 'en-default':
        case 'gb':
        case 'gb-default':
            button.label = 'Make a new reservation';
            break;
        case 'fr':
        case 'fr-default':
            button.label = 'Faire une nouvelle réservation';
            break;
        case 'pt':
        case 'pt-default':
            button.label = 'Fazer uma nova reserva';
            break;
        case 'it':
        case 'it-default':
            button.label = 'Effettua una nuova prenotazione';
            break;
        case 'es':
        case 'es-default':
            button.label = 'Hacer una nueva reserva';
            break;
    }
});

console.error(JSON.parse(JSON.stringify(newButtons )));
console.error(JSON.parse(JSON.stringify(newConfig.steps[0] )));



const extraIntroStep = {
    type: 'default',
    code: 'intro0',
    title: 'title-intro-0',
    buttons: [
        {
            label: 'btn-next',
            code: 'start-booking0',
        },
    ],
    layout: {
        rows: [
            {
                columns: [
                    {
                        type: 'title',
                        classes: ['col-12'],
                    },
                ],
            },
            {
                columns: [
                    {
                        type: 'text',
                        classes: ['col-12'],
                        data: 'intro-text-0',
                    },
                ],
            },
            {
                columns: [
                    {
                        type: 'buttons',
                        classes: ['col-12'],
                    },
                ],
            },
        ],
    },
};

newConfig.steps.splice(1, 0, extraIntroStep);
// newConfig.steps.splice(2, 1);

export default newConfig;
