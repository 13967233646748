import Vue from 'vue';
import store from './store/index';

const moment = require('moment');
const countries: any = require('i18n-iso-countries');

Vue.filter('map_tommy_locale', function(value: any): any {
  return 'en' === value ? 'gb' : value;
});

Vue.filter('date_format', function(value: any, config?: any): any {
  const defaults: any = {
    type: 'date',
    value: value,
    suffix: '',
  };
  const options: any = {
    ...defaults,
    ...config,
  };

  if (options.type === 'date-full') {
    options.format = 'dddd D MMMM YYYY';
  } else if (options.type === 'date-full-no-year') {
    options.format = 'D MMMM';
  } else if (options.type === 'datetime-full') {
    options.format = 'dddd D MMMM YYYY HH:mm';
  } else if (options.type === 'datetime-full-no-year') {
    options.format = 'dddd D MMMM HH:mm';
  } else if (options.type === 'datetime') {
    options.format = 'YYYY-MM-DD HH:mm';
  } else if (options.type === 'time') {
    options.format = 'HH:mm';
  } else {
    options.format = 'YYYY-MM-DD';
  }

  if (true === options.seconds) {
    options.suffix = ':ss';
  }

  let date: any = value;
  if (typeof value === 'string') {
    date = moment(value);
  } else if (typeof value.getFullYear !== 'undefined') {
    date = moment(value);
  }

  if (typeof date.format === 'undefined') {
    throw 'Unexpected value for date formatting "' + typeof date + '"';
  }

  return date.format(options.format + options.suffix);
});

Vue.filter('number_format', function(value: any, config?: any): any {
  const defaults: any = {
    type: 'price',
    prefix: '',
    value: value,
  };
  const options: any = {
    ...defaults,
    ...config,
  };

  if (options.type === 'price') {
    options.prefix = '€ ';
  } else {
    options.prefix = '';
  }

  return (
    '' +
    options.prefix +
    parseFloat('' + options.value)
      .toFixed(2)
      .replace('.', ',')
  );
});

Vue.filter('country_name', function(value: any, config?: any): any {
  return countries.getName(value, config.locale || store.getters.locale);
});

Vue.filter('tommy_translate', function(object: any, field: string, fallbackField?: string): string {
  const tommyLocale = store.getters.tommyLocale;
  const collection = object[field] || false;
  if (false !== collection) {
    if (collection[tommyLocale]) {
      return collection[tommyLocale];
    }
  }

  if (!fallbackField) {
    return '';
  }

  const fallback = object[fallbackField] || false;
  if (false === fallback) {
    return '';
  }

  if (typeof fallback == 'object') {
    return fallback[tommyLocale] || '';
  }

  return fallback || '';
});
