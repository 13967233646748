
import Vue from 'vue';
import Progress from '@/components/Progress.vue';
import NotifyPopup from '@/components/NotifyPopup.vue';
import { mapGetters } from 'vuex';
import { StepInterface } from '@/model/';
import { wizardProvider } from '@/wizard/';
import moment from 'moment';

export default Vue.extend({
  props: {
    config: {
      type: Object,
      default: () => false as any,
    },
  },
  components: {
    Progress,
    NotifyPopup,
  },
  data() {
    return {
      resultUrl: false as any,
      done: false as boolean,
      timeout: false as any,
    };
  },
  mounted() {
    this.$store.dispatch('stopLoader');
  },
  methods: {
    stepClass(step: StepInterface, stepIndex: number): string[] {
      if (this.currentStepIndex === stepIndex) {
        return ['step', 'active'];
      }

      return ['step'];
    },
    async updateStep(options: any) {
      clearTimeout(this.timeout);
      this.startCountdown();

      this.$store.dispatch('startLoader');

      const choice: any = options;
      const step: StepInterface = this.steps[this.currentStepIndex];
      delete choice.step;

      this.$emit('update:choices', {
        stepNumber: this.currentStepIndex + 1,
        data: choice,
      });

      this.$store.commit('SET_CHOICE', {
        stepNumber: this.currentStepIndex + 1,
        data: choice,
      });

      const continueNext: boolean = await wizardProvider.stepDone(step, this.$store.getters.choices);
      if (!continueNext) {
        this.$store.dispatch('stopLoader');
        return false;
      }

      if (this.stepNumber >= this.stepCount) {
        this.$store.commit('SET_STEP', null);
        const resultUrl: any = wizardProvider.buildUrl(this.choices) as any;

        this.$store.dispatch('stopLoader');

        if ('1' !== (process.env['VUE_APP_CYPRESS_RUN'] || false) && false !== resultUrl) {
          document.location = resultUrl;
        } else {
          this.resultUrl = resultUrl;
          this.done = true;
        }
      } else {
        this.$store.dispatch('updateMountKey');
        this.$store.commit('SET_STEP_NUMBER', this.stepNumber + 1);
      }
    },
    startCountdown(): any {
      this.timeout = setTimeout(() => {
        this.$store.dispatch('showTimeout');
      }, parseInt(process.env.VUE_APP_INACTIVE_TIMEOUT));
    },
  },
  computed: {
    currentStepIndex(): number {
      return this.stepNumber - 1;
    },
    currentStep(): StepInterface {
      return this.steps[this.currentStepIndex];
    },
    steps(): StepInterface[] {
      return (this.config as any)['steps'] || [];
    },
    progress(): number {
      return (this.stepNumber / this.stepCount) * 100;
    },
    formatChoices(): string[] {
      let items: any = [];
      Object.keys(this.choices).forEach((key: string) => {
        if (this.choices[key].button) {
          items.push(key + ': ' + this.choices[key].button.code);
        } else {
          items.push(key + ': ' + JSON.stringify(this.choices[key]));
        }
      });

      return items;
    },
    ...mapGetters(['stepNumber', 'stepCount', 'choices']),
  },
});
