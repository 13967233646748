import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'camperparkamsterdam';

const extraIntroStep = {
    type: 'default',
    code: 'intro0',
    title: 'title-intro-0',
    buttons: [
        {
            label: 'btn-next',
            code: 'start-booking0',
        },
    ],
    layout: {
        rows: [
            {
                columns: [
                    {
                        type: 'title',
                        classes: ['col-12'],
                    },
                ],
            },
            {
                columns: [
                    {
                        type: 'text',
                        classes: ['col-12'],
                        data: 'intro-text-0',
                    },
                ],
            },
            {
                columns: [
                    {
                        type: 'buttons',
                        classes: ['col-12'],
                    },
                ],
            },
        ],
    },
};

newConfig.steps.splice(1, 0, extraIntroStep);
newConfig.steps.splice(2, 1);

newConfig.steps[0].buttons.forEach((button: any) => {
    switch (button.code) {
        case 'nl':
        case 'nl-default':
            button.label = 'Nieuwe reservering plaatsen';
            break;
        case 'de':
        case 'de-default':
            button.label = 'Neue Reservierung vornehmen';
            break;
        case 'en':
        case 'en-default':
        case 'gb':
        case 'gb-default':
            button.label = 'Make a new reservation';
            break;
        case 'fr':
        case 'fr-default':
            button.label = 'Faire une nouvelle réservation';
            break;
        case 'pt':
        case 'pt-default':
            button.label = 'Fazer uma nova reserva';
            break;
        case 'it':
        case 'it-default':
            button.label = 'Effettua una nuova prenotazione';
            break;
        case 'es':
        case 'es-default':
            button.label = 'Hacer una nueva reserva';
            break;
    }
});

export default newConfig;
