
import Vue from 'vue';
import TravelGroupWidget from '@/wizard/Tommy/Widget/TravelGroupWidget.vue';
import FloorplanWidget from '@/wizard/Tommy/Widget/FloorplanWidget.vue';
import DurationWidget from '@/wizard/Tommy/Widget/DurationWidget.vue';
import ReceiptWidget from '@/wizard/Tommy/Widget/ReceiptWidget.vue';
import ReceiptDetailsWidget from '@/wizard/Tommy/Widget/ReceiptDetailsWidget.vue';
import AccommodationWidget from '@/wizard/Tommy/Widget/AccommodationWidget.vue';
import ExtrasWidget from '@/wizard/Tommy/Widget/ExtrasWidget.vue';
import PinWidget from '@/wizard/Tommy/Widget/PinWidget.vue';
import ReductionpassWidget from '@/wizard/Tommy/Widget/ReductionpassWidget.vue';
import PetsWidget from '@/wizard/Tommy/Widget/PetsWidget.vue';

import { ButtonInterface } from '../../model';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    TravelGroupWidget,
    FloorplanWidget,
    DurationWidget,
    ReceiptWidget,
    ReceiptDetailsWidget,
    AccommodationWidget,
    ExtrasWidget,
    PinWidget,
    ReductionpassWidget,
    PetsWidget,
  },
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    stepIndex: {
      type: Number,
      default: () => false as any,
    },
    stepInput: {
      type: Object,
      default: () => false as any,
    },
  },
  watch: {
    stepIndex: function(newValue: any): any {
      for (var i = 0; i <= this.stepCount + 1; i++) {
        document.body.classList.remove('step-' + i);
      }

      document.body.classList.add('step-' + (newValue + 1));
    },
  },
  methods: {
    choose(data: any) {
      this.$emit('choose', data);
    },
    chooseWidget(data: any) {
      this.$emit('choose', data);
    },
    stepKey(name: string): string {
      return '' + name + '-' + this.mountKey + '-' + (this.active ? 'active' : 'inactive');
    },
  },
  computed: {
    ...mapGetters(['mountKey', 'stepCount']),
  },
});
