
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { wizardProvider } from '@/wizard/';

export default Vue.extend({
  computed: {
    ...mapGetters(['loading', 'meta']),
  },
  data() {
    return {
      wizardProvider: wizardProvider,
    };
  },
  methods: {
    getClass(): string[] {
      const classList: string[] = ['loading-indicator'];
      if (this.loading) {
        classList.push('enabled');
      }

      return classList;
    },
  },
});
