
import Vue, { CreateElement } from 'vue';

export default Vue.extend({
  props: {
    progress: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    getStyle() {
      return 'width: ' + Math.round(this.progress) + '%';
    },
  },
});
