
import Vue from 'vue';
import NumberToggle from '@/components/NumberToggle.vue';
import { wizardProvider } from '@/wizard';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    NumberToggle,
  },
  props: {
    widget: {
      type: Object,
      default: () => false as any,
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      values: {} as any,
      ready: false,
    };
  },
  mounted() {
    this.apiPersonCategories.forEach((personCategory: any) => {
      const key: string = personCategory.label && '' !== '' + personCategory.label ? personCategory.label : personCategory.id;

      Vue.set(this.values, key, {
        value: personCategory.default,
        id: personCategory.id,
        label: Vue.filter('tommy_translate')(personCategory, 'name', 'label'),
      });
    });

    this.ready = true;
  },
  methods: {
    getMin: function(key: any): any {
      return this.getMinMax(key, true);
    },
    getMax: function(key: any): any {
      return this.getMinMax(key, false);
    },
    getMinMax: function(key: any, min: boolean): any {
      const tg = this.apiTravelGroupLimits.travelGroups;
      const val: any = this.values[key];
      if (!val) {
        return null;
      }
      const tgValue: any = tg[val.id];
      if (!tgValue) {
        return null;
      }

      if (true === min) {
        return tgValue.min || null;
      }

      return tgValue.max || null;
    },
    triggerClick: function(): any {
      if (!this.validate(null, false)) {
        return;
      }

      const values: any = {};
      Object.keys(this.values).forEach((key: any) => {
        let id: number = 0;
        this.apiPersonCategories.forEach((personCategory: any) => {
          const labelKey: string =
            personCategory.label && '' !== '' + personCategory.label ? personCategory.label : personCategory.id;
          if (labelKey === key) {
            id = personCategory.id;
          }
        });

        if (id > 0) {
          values[id] = Object.assign({}, this.values[key]);
        }
      });

      this.$emit('choose', { data: values });
    },
    validate: function(key: string, setMessage?: boolean): boolean {
      if (false !== this.totalMaxTravelGroup && this.totalCount > this.totalMaxTravelGroup) {
        if (setMessage && setMessage === true) {
          this.$store.dispatch('showPopup', wizardProvider.translate('travelgroup-too-many'));
        }

        if (key) {
          Vue.set(this.values[key], 'value', this.values[key].value - 1);
        }

        return false;
      }

      return true;
    },
  },
  computed: {
    totalMaxTravelGroup(): number | boolean {
      return this.apiTravelGroupLimits.global.max || false;
    },
    adultPersonCategories(): any {
      const ids: any = [];
      this.apiPersonCategories.forEach((personCategory: any) => {
        const from: number | boolean = personCategory.from || false;
        const until: number | boolean = personCategory.until || false;

        if (from >= 18) {
          ids.push(personCategory);
        }
      });

      return ids;
    },
    totalCount(): number {
      let num: number = 0;
      this.apiPersonCategories.forEach((personCategory: any) => {
        const key: string = personCategory.label && '' !== '' + personCategory.label ? personCategory.label : personCategory.id;

        if (this.values[key] && this.values[key].value > 0) {
          num += this.values[key].value;
        }
      });

      return num;
    },
    adultCount(): number {
      let num: number = 0;
      this.adultPersonCategories.forEach((personCategory: any) => {
        const key: string = personCategory.label && '' !== '' + personCategory.label ? personCategory.label : personCategory.id;

        if (this.values[key] && this.values[key].value > 0) {
          num += this.values[key].value;
        }
      });

      return num;
    },
    travelGroupCount(): number {
      let number = 0;
      Object.entries(this.values).forEach((item: any) => {
        number += item[1].value;
      });

      return number;
    },
    ...mapGetters(['apiPersonCategories', 'apiTravelGroupLimits', 'locale']),
  },
});
