
import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    limitMin: {
      type: Number,
      default: () => 0 as number,
      required: false,
    },
    limitMax: {
      type: Number,
      default: () => null as number,
      required: false,
    },
    minIcon: {
      type: Array,
      default: () => ['fas', 'minus'] as any,
      required: false,
    },
    plusIcon: {
      type: Array,
      default: () => ['fas', 'plus'] as any,
      required: false,
    },
  },
  mounted() {
    if (null !== this.limitMin && this.value < this.limitMin) {
      this.$emit('input', this.limitMin);
    }

    if (null !== this.limitMax && this.value > this.limitMax) {
      this.$emit('input', this.limitMax);
    }
  },
  methods: {
    getClasses(type: string) {
      const classes: any = ['btn', 'btn-primary', 'btn-circle', 'btn-xl', 'handle-' + type];
      if (type === 'min' && this.value <= this.limitMin) {
        classes.push('disabled');
      }
      if (type === 'plus' && null !== this.limitMax && this.value >= this.limitMax) {
        classes.push('disabled');
      }

      return classes;
    },
    min() {
      if (this.value <= this.limitMin) {
        return;
      }

      this.$emit('input', this.value - 1);
    },
    plus() {
      if (this.limitMax === null || this.value < this.limitMax) {
        this.$emit('input', this.value + 1);
      } else {
        this.$emit('input', this.value);
      }
    },
  },
});
