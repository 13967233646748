import { StepInterface, ButtonInterface, ConfigInterface } from '@/model';
import NoopHelper from '../NoopHelper';
import config from './config';

import moment from 'moment';

export class DroomparkenHelper extends NoopHelper {
  getConfig(): ConfigInterface {
    return config;
  }

  provideTranslations(): any {
    return require('./translations.ts');
  }

  customizeStep(step: StepInterface, selection: any, choices: any): StepInterface {
    switch (step.type) {
      case 'verblijf':
        step = this.customizeDurationStep(step, selection, choices);
        break;
      case 'personen':
        step = this.customizeTravelgroupStep(step, selection, choices);
        break;
    }

    switch (step.code) {
      case 'waardroomjegraag':
        step = this.customizeDreamLocationStep(step, selection, choices);
        break;
    }

    return step;
  }

  async overrideStep(step: StepInterface, selection: any, choices: any): Promise<boolean | StepInterface> {
    return false;
  }

  customizeDurationStep(step: StepInterface, selection: any, choices: any): StepInterface {
    if (selection.includes('duur-weekend')) {
      step.skip = 'volgende-vrijdag';
    } else if (selection.includes('duur-week')) {
      // add buttons for next monday and next friday.
      let i = 0;
      while (step.buttons.length < 2) {
        const weekday = moment().add(i, 'days');
        if ([1, 5].includes(weekday.weekday())) {
          step.buttons.push({
            label: (1 == weekday.weekday() ? 'ma' : 'vr') + ' ' + weekday.format('DD-MM'),
            code: 'volgende-' + (1 == weekday.weekday() ? 'maandag' : 'vrijdag'),
          });
        }

        i++;
      }
    } else {
      step.skip = 'volgende-maandag';
    }

    return step;
  }

  customizeTravelgroupStep(step: StepInterface, selection: any, choices: any): StepInterface {
    if (!step.options) {
      step.options = {};
    }

    if (selection.includes('reisgezelschap-kiddos')) {
      step.options.widget = {
        type: 'form',
        options: {
          type: 'reisgezelschap',
          fields: [
            { type: 'text', label: 'Aantal kinderen', name: 'kinderen', default: 1 },
            { type: 'text', label: "Aantal baby's", name: 'babys', default: 0 },
          ],
        },
      };
    } else if (selection.includes('reisgezelschap-clubje') || selection.includes('reisgezelschap-heelveel')) {
      step.options.widget = {
        type: 'form',
        options: {
          type: 'reisgezelschap',
          fields: [{ type: 'text', label: 'Aantal personen', name: 'personen', default: 2 }],
        },
      };
    } else {
      step.skip = { type: 'vastepersonen', code: 2 };
    }

    return step;
  }

  customizeDreamLocationStep(step: StepInterface, selection: any, choices: any): StepInterface {
    const travelGroupCount: number = this.calculateTravelGroupSum(selection, choices);
    step.buttons = step.buttons.filter((button: ButtonInterface) => {
      if (button.options && button.options.max && travelGroupCount > button.options.max) {
        return false;
      }

      if (button.options && button.options.min && travelGroupCount < button.options.min) {
        return false;
      }

      return true;
    });

    return step;
  }

  calculateTravelGroupSum(selection: any, choices: any): number {
    let sum: number = 0;
    const result: any = this.buildChoiceResults(selection, choices);
    Object.keys(result.travelgroup).forEach((key: any) => {
      sum += parseInt(result.travelgroup[key]);
    });

    return sum;
  }

  buildUrl(selection: any, choices: any): string {
    return 'https://www.droomparken.nl/zoek-en-boek?' + this.buildQueryParams(this.buildChoiceResults(selection, choices));
  }

  parseChoice(choice: any): any {
    if (!choice) {
      return false;
    }

    if (typeof choice.button !== 'undefined') {
      return choice.button.code || false;
    }

    return choice;
  }

  buildChoiceResults(selection: any, choices: any): any {
    const result: any = {
      travelgroup: {
        Adult: 0,
      },
      period: false,
      house: false,
      stay: false,
      location: false,
    };

    const dataSet: any = {
      aantal:
        typeof choices['aantal'] !== 'undefined' && typeof choices['aantal'].data !== 'undefined' ? choices['aantal'].data : {},
      reisgezelschap: choices['reisgezelschap'] || {},
      duur: choices['duur'] || false,
      wanneer: choices['wanneer'] || false,
    };

    if (typeof dataSet.reisgezelschap !== 'undefined' && typeof dataSet.reisgezelschap.button !== 'undefined') {
      switch (dataSet.reisgezelschap.button.code) {
        case 'lief':
          result.travelgroup.Adult = 2;
          break;
        case 'kiddos':
          result.travelgroup.Adult = 2;
          result.travelgroup.Child = parseInt(dataSet.aantal['kinderen'] || 0);
          result.travelgroup.Baby = parseInt(dataSet.aantal['babys'] || 0);
          break;
        case 'clubje':
        case 'heelveel':
          result.travelgroup.Adult = parseInt(dataSet.aantal['personen'] || 0);
          break;
      }
    }

    const wanneerButtonValue: any = dataSet.wanneer ? dataSet.wanneer.button.code : false;
    let requestWeekDay: any = false;
    if (selection.includes('wanneer-volgende-maandag') || wanneerButtonValue === 'volgende-maandag') {
      requestWeekDay = 1;
    } else if (selection.includes('wanneer-volgende-vrijdag') || wanneerButtonValue === 'volgende-vrijdag') {
      requestWeekDay = 5;
    }

    let startDate: any = false;
    if (false !== requestWeekDay) {
      let i = 1;
      let found: boolean = false;
      while (!found) {
        const weekday = moment().add(i, 'days');
        if ('' + requestWeekDay === '' + weekday.weekday()) {
          found = true;
          startDate = weekday;
        }

        i++;
      }
    }

    if (startDate !== false && dataSet.duur !== false) {
      let durationDays: number = 2;
      switch (dataSet.duur.button.code) {
        case 'weekend':
          durationDays = 2;
          break;
        case 'midweek':
          durationDays = 4;
          break;
        case 'week':
          durationDays = 7;
          break;
      }

      const endDate: any = startDate.clone().add(durationDays, 'days');

      result.period = { start: startDate.format('YYYY-MM-DD'), end: endDate.format('YYYY-MM-DD') };
    }

    if (selection.includes('welkeregio-limburg')) {
      result.location = ['8']; // maasduinen
    } else if (selection.includes('welkeregio-zeeuwsekust')) {
      result.location = ['2', '11']; // bad meersee - zeeuwse kust
    } else if (selection.includes('welkeregio-develuwe')) {
      result.location = ['1', '3', '4', '6', '7', '10', '13']; // badhoophuizen - beekbergen - bospark garderen - de zanding - hooge veluwe - badhulckesteijn - zuiderzee
    } else if (selection.includes('welkeregio-noordholland')) {
      result.location = ['5', '9', '12']; // buitenhuizen - molengroet - spaarnwoude
    } else if (selection.includes('welkeregio-achterhoek')) {
      result.location = ['16']; // marina strandbad
    }

    if (selection.includes('waardroomjegraag-chalet')) {
      result.house = ['209'];
    } else if (selection.includes('waardroomjegraag-studio')) {
      result.house = ['956'];
    } else if (selection.includes('waardroomjegraag-cube')) {
      result.house = ['210'];
    } else if (selection.includes('waardroomjegraag-villas')) {
      result.house = ['211'];
    } else if (selection.includes('waardroomjegraag-tinyhouse')) {
      result.house = ['949'];
    } else if (selection.includes('waardroomjegraag-kamperen')) {
      result.house = ['957'];
    }

    return result;
  }

  private buildQueryParams(params: Object): string {
    return Object.entries(params)
      .filter(([key, value]) => value !== undefined && value !== null && value !== false)
      .map(([key, value]) => {
        if (Array.isArray(value) || typeof value === 'object') {
          value = JSON.stringify(value);
        }

        return `${key}=${value}`;
      })
      .join('&');
  }
}
