
import Vue from 'vue';
import { wizardProvider } from '@/wizard/';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    button: {
      type: Object,
      default: () => false as any,
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
    };
  },
  methods: {
    triggerClick: function() {
      this.$emit('choose', this.button);
    },
  },
  computed: {
    translate: function(): boolean {
      const options: any = this.button.options || {};

      return false !== options.translate;
    },
  },
});
