import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'lutjekossink';
newConfig.steps = newConfig.steps.filter((step: any) => {
  return step.code !== 'floorplan';
});

newConfig.steps[0].buttons.forEach((button: any) => {
  switch (button.code) {
    case 'nl':
      button.label = 'Boek een kampeerplek';
      break;
    case 'de':
      button.label = 'Buchen Sie einen Stellplatz';
      break;
    case 'en':
    case 'gb':
      button.label = 'Book a camping spot';
      break;
  }
});

export default newConfig;
